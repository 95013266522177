<template>
  <!-- 设备菜单控制 -->
  <div :class="[
      odermenuOutAnimation ? 'footerOutAnim' : '',
      odermenuInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="odermenulistshow">
    <div class="switchbox">
      <div class="switchtop">
        <div class="minbox" v-if="odermenuInAnimation" @click="odermenulistClose">
          <div class="imgbox">
            <img src="../../../../assets/homeimg/c1.png" alt="">
          </div>
          <p>收起两侧</p>
        </div>
        <div class="minbox" v-if="odermenuOutAnimation" @click="odermenulistOpen">
          <div class="imgbox">
            <img src="../../../../assets/homeimg/c7.png" alt="">
          </div>
          <p>展开两侧</p>
        </div>
        <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index"
          @click="witchtopbtn(index)">
          <div class="imgbox">
            <img :src="itme.img" alt="">
          </div>
          <p>{{itme.name}}</p>
        </div>
        <div class="minbox">
          <div class="imgbox">
            <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false"
              @click="isSwitch" />
          </div>
          <p>三维切换</p>
        </div>
        <div class="minbox" v-if="terrain">
          <div class="imgbox">
            <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false"
              @click="istype" />
          </div>
          <p>地形切换</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data () {
    return {
      listshow: true, // 控制菜单显示
      footerOutAnimation: false, // 列表侧淡出
      odermenuOutAnimation: false, // 设备列表侧淡出
      odermenuInAnimation: true, // 设备列表淡入
      terrain: false, // 地形切换显示

      footerInAnimation: true, // 列表淡入
      witchtoplist: [
        {
          img: require('../../../../assets/homeimg/c2.png'),
          name: '测距离'
        },
        {
          img: require('../../../../assets/homeimg/c3.png'),
          name: '测面积'
        },
        {
          img: require('../../../../assets/homeimg/c4.png'),
          name: '初始视角'
        },
        {
          img: require('../../../../assets/homeimg/c5.png'),
          name: 'GIS切换'
        },
        {
          img: require('../../../../assets/homeimg/c8.png'),
          name: '地图查询'
        },
        {
          img: require('../../../../assets/homeimg/c9.png'),
          name: '信息服务'
        },
        {
          img: require('../../../../assets/homeimg/dian@2x.png'),
          name: '描点开关'
        }
      ]
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    odermenulistClose () {
      this.equipmentClosed()
      this.equipmentrightClosed()
      this.odermenuOutAnimation = true
      this.odermenuInAnimation = false
    },
    // 设备菜单开启
    odermenulistOpen () {
      this.equipmentOpen()
      this.equipmentrightOpen()
      this.odermenuOutAnimation = false
      this.odermenuInAnimation = true
    },
    // 菜单功能
    witchtopbtn (index) {
      if (index === 0) {
        this.env.EnableDistanceTool()
      } else if (index === 1) {
        this.env.EnableAreaTool()
      } else if (index === 2) {
        this.env.ResetMap()
      } else if (index === 3) {
        this.env.SwithBaseMap(this.gisnum)
        this.gisnum += 1
        if (this.gisnum === 3) {
          this.gisnum = 0
        }
      } else if (index === 4) {
        if (this.gispage) {
          this.searchboxshow = !this.searchboxshow
        }
      } else if (index === 5) {
        // const href = 'https://ais.msa.gov.cn' // 网站链接
        window.open('https://ais.msa.gov.cn', '_blank')
      } else if (index === 6) {
        this.env.EnableMarkerTool(true)
      }
    },
    isSwitch (checked, event) {
      if (checked) {
        this.env.SwitchDimensional(1)
        this.terrain = true
      } else {
        this.env.SwitchDimensional(0)
        this.terrain = false
        this.env.UnloadTerrain()
      }
    },
    istype (checked, event) {
      if (checked) {
        this.env.LoadTerrain()
      } else {
        this.env.UnloadTerrain()
      }
    }
  },
  created () {
  }
}
</script>
<style lang="less" scoped>
.menubox {
  position: absolute;
  left: 24%;
  top: 6%;
  width: 120px;
  z-index: 2;

  .switchbox {
    width: 120px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    .switchtop {
      width: 65px;
      height: 65px;
      .minbox {
        width: 65px;
        height: 65px;
        background: url('../../../../assets/homeimg/c6.png') no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 5px;
        overflow: hidden;
        cursor: pointer;

        .imgbox {
          width: 27px;
          height: 27px;
          margin-left: 19px;
          margin-top: 10px;
          cursor: pointer;
        }

        p {
          margin-top: 5px;
        }
      }

      .minbox:nth-child(9),
      .minbox:nth-child(10) {
        .imgbox {
          margin-left: 12px;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.footerOutAnim {
  animation: footerOut 1s;
  animation-fill-mode: forwards;
}
.footerInAnim {
  animation: footerIn 1s;
  animation-fill-mode: forwards;
}
@keyframes footerOut {
  from {
    left: 25%;
  }

  to {
    left: 2%;
  }
}
@keyframes footerIn {
  from {
    left: 2%;
  }

  to {
    left: 25%;
  }
}
</style>
