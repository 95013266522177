var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.odermenulistshow)?_c('div',{class:[
    _vm.odermenuOutAnimation ? 'footerOutAnim' : '',
    _vm.odermenuInAnimation ? 'footerInAnim' : '',
    'menubox',
  ]},[_c('div',{staticClass:"switchbox"},[_c('div',{staticClass:"switchtop"},[(_vm.odermenuInAnimation)?_c('div',{staticClass:"minbox",on:{"click":_vm.odermenulistClose}},[_vm._m(0),_c('p',[_vm._v("收起两侧")])]):_vm._e(),(_vm.odermenuOutAnimation)?_c('div',{staticClass:"minbox",on:{"click":_vm.odermenulistOpen}},[_vm._m(1),_c('p',[_vm._v("展开两侧")])]):_vm._e(),_vm._l((_vm.witchtoplist),function(itme,index){return _c('div',{key:index,staticClass:"minbox",on:{"click":function($event){return _vm.witchtopbtn(index)}}},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":itme.img,"alt":""}})]),_c('p',[_vm._v(_vm._s(itme.name))])])}),_c('div',{staticClass:"minbox"},[_c('div',{staticClass:"imgbox"},[_c('a-switch',{attrs:{"checkedChildren":"3D","unCheckedChildren":"2D","defaultChecked":false},on:{"click":_vm.isSwitch}})],1),_c('p',[_vm._v("三维切换")])]),(_vm.terrain)?_c('div',{staticClass:"minbox"},[_c('div',{staticClass:"imgbox"},[_c('a-switch',{attrs:{"checkedChildren":"开","unCheckedChildren":"关","defaultChecked":false},on:{"click":_vm.istype}})],1),_c('p',[_vm._v("地形切换")])]):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":require("../../../../assets/homeimg/c1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":require("../../../../assets/homeimg/c7.png"),"alt":""}})])
}]

export { render, staticRenderFns }